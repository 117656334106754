import routes from "./routes";

class TalentInvitationModule {
  static name() {
    return "TalentInvitationModule";
  }
  static getRoutes() {
    return routes;
  }
}

export default TalentInvitationModule;
